/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import vaultsConfig from 'config/constants/vaults';
import fetchVaults from 'state/vaults/fetchVaults';
import {
  fetchVaultUserRewards,
  fetchVaultUserAllowances,
  fetchVaultUserTokenBalances,
  fetchVaultUserDepositedBalances,
  fetchUserWalletVaultTokenBalances,
} from './fetchVaultUser';
import { VaultsState, Vault } from '../types';

const initialState: VaultsState = { data: [...vaultsConfig] };

export const vaultsSlice = createSlice({
  name: 'Vaults',
  initialState,
  reducers: {
    setVaultsPublicData: (state, action) => {
      const liveVaultsData: Vault[] = action.payload;
      state.data = state.data.map((vault) => {
        const liveVaultData = liveVaultsData.find((f) => f.vid === vault.vid && f.migration === vault.migration);
        return { ...vault, ...liveVaultData };
      });
    },
    setVaultUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl;
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
  },
});

// Actions
export const { setVaultsPublicData, setVaultUserData } = vaultsSlice.actions;

// Thunks
export const fetchVaultsPublicDataAsync = () => async (dispatch) => {
  const vaults = await fetchVaults();
  dispatch(setVaultsPublicData(vaults));
};
export const fetchVaultUserDataAsync = (account) => async (dispatch) => {
  const userVaultAllowances = await fetchVaultUserAllowances(account);
  const userVaultTokenBalances = await fetchVaultUserTokenBalances(account);
  const userDepositedBalances = await fetchVaultUserDepositedBalances(account);
  const userVaultRewards = await fetchVaultUserRewards(account);
  const userWalletVaultTokenBalances = await fetchUserWalletVaultTokenBalances(account);

  const arrayOfUserDataObjects = userVaultAllowances.map((vaultAllowance, index) => {
    return {
      index,
      allowance: userVaultAllowances[index],
      tokenBalance: userVaultTokenBalances[index],
      stakedBalance: userDepositedBalances[index],
      earnings: userVaultRewards[index],
      walletTokenBalance: userWalletVaultTokenBalances[index],
    };
  });

  dispatch(setVaultUserData({ arrayOfUserDataObjects }));
};

export default vaultsSlice.reducer;
