import { Flex, Skeleton, Text, TooltipText, useTooltip } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import ExpandableSectionButton from 'components/ExpandableSectionButton';
import { BASE_SWAP_URL } from 'config';
import useI18n from 'hooks/useI18n';
import React, { useMemo, useState } from 'react';
import { usePoolUser } from 'state/hooks';
import { Pool } from 'state/types';
import { getAddress } from 'utils/addressHelpers';
import { getFullDisplayBalance } from 'utils/formatBalance';
import getBlockExplorerUrl from 'utils/getBlockExplorerUrl';
import PoolCountdown from 'views/Pools/PoolCountdown';
import { provider as ProviderType } from 'web3-core';

import CardActionsContainer from './CardActionsContainer';
import CardHeading from './CardHeading';
import DetailsSection from './DetailsSection';
import { Divider, ExpandingWrapper, FCard, StyledCardAccent, WalletBalance, WalletBalanceTitle } from './styles';

export interface PoolWithStakedValue extends Pool {
  apr?: number;
  liquidity?: BigNumber;
  tokenUsdPrice: number;
}

interface PoolCardProps {
  pool: PoolWithStakedValue;
  removed: boolean;
  provider?: ProviderType;
  account?: string;
}

const DEPOSIT_FEE = '0';

const PoolCard: React.FC<PoolCardProps> = ({ pool, removed, account }) => {
  const { pid, apr, liquidity, stakingToken, multiplier, poolState, tokenUsdPrice } = pool;
  const TranslateString = useI18n();
  const { walletTokenBalance } = usePoolUser(pid);
  const displayBalance = useMemo(() => {
    const fullBalance = getFullDisplayBalance(walletTokenBalance);
    const isBalanceZero = fullBalance === '0' || !fullBalance;

    return isBalanceZero ? '0' : parseFloat(fullBalance).toFixed(4);
  }, [walletTokenBalance]);

  const [showExpandableSection, setShowExpandableSection] = useState(false);

  const poolImage = stakingToken?.symbol.toLowerCase();

  const totalValueFormated = liquidity
    ? `$${liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
    : '-';

  const tokenLabel = stakingToken.symbol.toUpperCase();

  const displayPoolAPR = apr?.toLocaleString(undefined, { maximumFractionDigits: 2 });
  const displayPoolDailyAPR = apr ? (apr / 365).toLocaleString(undefined, { maximumFractionDigits: 2 }) : undefined;

  const getSwapTokenUrl = `${BASE_SWAP_URL}${getAddress(stakingToken.address)}`;
  const poolContractBscScanAddress = pool.poolAddresses ? getBlockExplorerUrl(pool.poolAddresses) : '';

  const getTokenUrl = getSwapTokenUrl;

  const TVLtooltipContent = TranslateString(998, 'The total amount, in American dollars, deposited in this Pool');
  const BalanceTooltipContent = TranslateString(1046, 'The total amount of this token in your wallet');

  const { targetRef: TVLtargetRef, tooltip: TVLtooltip, tooltipVisible: TVLtooltipVisible } = useTooltip(
    TVLtooltipContent,
    {
      placement: 'bottom-start',
    },
  );
  const {
    targetRef: balanceTargetRef,
    tooltip: balanceTooltip,
    tooltipVisible: balanceTooltipVisible,
  } = useTooltip(BalanceTooltipContent, { placement: 'bottom-start' });

  const isPromotedPool = stakingToken.symbol.toUpperCase() === 'BALLE';

  return (
    <FCard>
      {isPromotedPool && <StyledCardAccent />}
      <CardHeading
        tokenLabel={tokenLabel}
        multiplier={multiplier}
        poolImage={poolImage}
        tokenSymbol={stakingToken.symbol}
        poolState={poolState}
      />
      <Divider />
      {!removed && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Text>APR</Text>
            <Text bold style={{ display: 'flex', alignItems: 'center' }}>
              {pool.apr ? <>{displayPoolAPR}%</> : <Skeleton height={24} width={80} />}
            </Text>
          </Flex>

          <Flex justifyContent="space-between" alignItems="center">
            <Text>{TranslateString(986, 'Daily')}</Text>
            <Text bold style={{ display: 'flex', alignItems: 'center' }}>
              {pool.apr ? <>{displayPoolDailyAPR}%</> : <Skeleton height={24} width={80} />}
            </Text>
          </Flex>
        </>
      )}
      {TVLtooltipVisible && TVLtooltip}
      <Flex justifyContent="space-between">
        <TooltipText ref={TVLtargetRef}>TVL</TooltipText>
        <Text bold>{totalValueFormated}</Text>
      </Flex>

      {!removed && <PoolCountdown pool={pool} />}
      <Flex justifyContent="space-between" alignItems="center">
        <Text>{TranslateString(972, 'Deposit Fee')}:</Text>
        <Text bold style={{ display: 'flex', alignItems: 'center' }}>
          {DEPOSIT_FEE}
        </Text>
      </Flex>

      <CardActionsContainer
        account={account}
        getTokenUrl={getTokenUrl}
        pool={pool}
        walletTokenBalance={walletTokenBalance}
      />

      <Flex alignItems="flex-end" justifyContent="space-between">
        {balanceTooltipVisible && balanceTooltip}
        <Flex flexDirection="column">
          <WalletBalanceTitle>
            <TooltipText ref={balanceTargetRef} bold textTransform="uppercase" color="secondary" fontSize="12px">
              {`${pool.stakingToken.symbol.toUpperCase()} ${TranslateString(944, 'Balance')}`}
            </TooltipText>
          </WalletBalanceTitle>
          <WalletBalance color={displayBalance === '0' ? 'textDisabled' : 'text'}>{displayBalance}</WalletBalance>
        </Flex>

        <ExpandableSectionButton
          onClick={() => setShowExpandableSection(!showExpandableSection)}
          expanded={showExpandableSection}
        />
      </Flex>
      <ExpandingWrapper expanded={showExpandableSection}>
        <DetailsSection
          poolContractBscScanAddress={poolContractBscScanAddress}
          tokenLabel={tokenLabel}
          getTokenUrl={getTokenUrl}
          tokenUsdPrice={tokenUsdPrice}
        />
      </ExpandingWrapper>
    </FCard>
  );
};

export default PoolCard;
