import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchVaultUserDataAsync, fetchPoolUserDataAsync } from 'state/actions';
import { approve, approveAddress } from 'utils/callHelpers';
import { Contract } from 'web3-eth-contract';

import { useBalleMaster, useERC20 } from './useContract';

// Approve a Token
export const useTokenApprove = (tokenAddress: string, spenderAddress: string) => {
  const { account } = useWeb3React();
  const tokenContract = useERC20(tokenAddress);

  const handleApprove = useCallback(async () => {
    try {
      const spenderWrapper = { options: { address: spenderAddress } };
      const tx = await approve(tokenContract, spenderWrapper, account);
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, tokenContract, spenderAddress]);

  return { onApprove: handleApprove };
};

// Approve a Vault
export const useApprove = (lpContract: Contract, migration: boolean) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const balleMasterContract = useBalleMaster(migration);

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, balleMasterContract, account);
      dispatch(fetchVaultUserDataAsync(account));
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, dispatch, lpContract, balleMasterContract]);

  return { onApprove: handleApprove };
};

// Approve a Pool
export const usePoolApprove = (tokenContract: Contract, poolAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approveAddress(tokenContract, poolAddress, account);
      dispatch(fetchPoolUserDataAsync(account));
      return tx;
    } catch (e) {
      return false;
    }
  }, [account, dispatch, tokenContract, poolAddress]);

  return { onApprove: handleApprove };
};
