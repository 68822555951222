/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import poolsConfig from 'config/constants/pools';
import fetchPools from 'state/pools/fetchPools';

import { Pool, PoolsState } from '../types';
import {
  fetchPoolUserAllowances,
  fetchPoolUserRewards,
  fetchPoolUserStakedBalances,
  fetchUserWalletPoolTokenBalances,
} from './fetchPoolUser';

const initialState: PoolsState = { data: [...poolsConfig] };

export const poolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload;
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((f) => f.pid === pool.pid);
        return { ...pool, ...livePoolData };
      });
    },
    setPoolUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl;
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
  },
});

// Actions
export const { setPoolsPublicData, setPoolUserData } = poolsSlice.actions;

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch) => {
  const pools = await fetchPools();
  dispatch(setPoolsPublicData(pools));
};
export const fetchPoolUserDataAsync = (account) => async (dispatch) => {
  const userPoolAllowances = await fetchPoolUserAllowances(account);
  const userPoolStakedBalances = await fetchPoolUserStakedBalances(account);
  const userPoolRewards = await fetchPoolUserRewards(account);
  const userWalletPoolTokenBalances = await fetchUserWalletPoolTokenBalances(account);

  const arrayOfUserDataObjects = userPoolAllowances.map((poolAllowance, index) => {
    return {
      index,
      allowance: userPoolAllowances[index],
      stakedBalance: userPoolStakedBalances[index],
      earnings: userPoolRewards[index],
      walletTokenBalance: userWalletPoolTokenBalances[index],
    };
  });

  dispatch(setPoolUserData({ arrayOfUserDataObjects }));
};

export default poolsSlice.reducer;
