import { Flex, Heading, Image, Spinner, Tag } from '@ballena-io/ballena-uikit';
import { RetiredTag } from 'components/Tags';
import React from 'react';
import { PoolStateEnum } from 'state/types';
import styled from 'styled-components';

export interface ExpandableSectionProps {
  tokenLabel?: string;
  multiplier?: string;
  poolImage?: string;
  tokenSymbol?: string;
  poolState?: string;
}

const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`;

const MultiplierTag = styled(Tag)`
  margin-left: 4px;
`;

const CardHeading: React.FC<ExpandableSectionProps> = ({
  tokenLabel,
  multiplier,
  poolImage,
  tokenSymbol,
  poolState,
}) => {
  const isRetired = poolState === PoolStateEnum.FINISHED;

  return (
    <Wrapper justifyContent="space-between" alignItems="center" mb="12px">
      {poolImage === 'balle' ? (
        <Spinner size={64} />
      ) : (
        <Image src={`/images/pools/${poolImage}.svg`} alt={tokenSymbol} width={64} height={64} />
      )}

      <Flex flexDirection="column" alignItems="flex-end">
        <Heading mb="4px">{tokenLabel.split(' ')[0]}</Heading>
        <Flex justifyContent="center">
          {isRetired && <RetiredTag mr="4px" />}
          <MultiplierTag variant="secondary">{multiplier}</MultiplierTag>
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default CardHeading;
