import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPoolUserDataAsync } from 'state/actions';
import { unstake, unstakeAll } from 'utils/callHelpers';

import { useStakingPool } from './useContract';

export const useUnstake = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const poolContract = useStakingPool(poolAddress);

  const handleUnstake = useCallback(
    async (amount: string) => {
      try {
        const txHash = await unstake(poolContract, amount, account);
        dispatch(fetchPoolUserDataAsync(account));
        console.info(txHash);
        return txHash;
      } catch (e) {
        return false;
      }
    },
    [account, dispatch, poolContract],
  );

  return { onUnstake: handleUnstake };
};

export const useUnstakeAll = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const poolContract = useStakingPool(poolAddress);

  const handleUnstakeAll = useCallback(async () => {
    try {
      const txHash = await unstakeAll(poolContract, account);
      dispatch(fetchPoolUserDataAsync(account));
      console.info(txHash);
      return txHash;
    } catch (e) {
      return false;
    }
  }, [account, dispatch, poolContract]);

  return { onUnstakeAll: handleUnstakeAll };
};

export default null;
