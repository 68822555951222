import BigNumber from 'bignumber.js';
import { getBlocksPerYear } from 'config/blockchain';
import { getAddress } from 'utils/addressHelpers';
import multicall from 'utils/multicall';

import { Vault } from '../../../state/types';
import { FarmPoolData } from '../types';
import masterChefABI from './abi/masterChef.json';
import lpTradeAprs from './lpTradeAprs.json';

const blocksPerYear = getBlocksPerYear();

/**
 * Get farm data
 * @param vault Vault to get data from (the underlying farm)
 * @returns
 */
export const getPancakeFarmData = async (vault: Vault): Promise<FarmPoolData> => {
  const farmAddress = getAddress(vault.farmAddresses);
  const [info, totalAllocPoint, cakePerBlock] = await multicall(masterChefABI, [
    {
      address: farmAddress,
      name: 'poolInfo',
      params: [vault.farmPid],
    },
    {
      address: farmAddress,
      name: 'totalAllocPoint',
    },
    {
      address: farmAddress,
      name: 'cakePerBlock',
    },
  ]);
  const allocPoint = new BigNumber(info.allocPoint._hex);
  const farmPoolWeight = allocPoint.div(new BigNumber(totalAllocPoint));
  const farmRewardPerBlock = new BigNumber(cakePerBlock).div(new BigNumber(10).pow(18));

  return {
    farmPoolWeight,
    farmRewardPerBlock,
  };
};

/**
 * Get farm APR value in %
 * @param vault Vault to calculate APR
 * @param cakePriceUsd CAKE price in USD
 * @param quoteTokenPriceUsd Quote token price in USD
 * @returns
 */
export const getPancakeFarmApr = (
  vault: Vault,
  cakePriceUsd: number,
  quoteTokenPriceUsd: number,
): { rewardsApr: number; lpTradeApr: number } => {
  const totalLiquidity = new BigNumber(vault.farmTotalInQuoteToken).times(quoteTokenPriceUsd);
  const yearlyCakeRewardAllocation = new BigNumber(vault.farmRewardPerBlock)
    .times(blocksPerYear)
    .times(vault.farmPoolWeight);
  const rewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(totalLiquidity).times(100);
  let rewardsAprAsNumber = null;
  if (!rewardsApr.isNaN() && rewardsApr.isFinite()) {
    rewardsAprAsNumber = rewardsApr.toNumber();
  }
  const lpTradeApr = lpTradeAprs[getAddress(vault.depositTokenAddresses).toLocaleLowerCase()] ?? 0;
  return { rewardsApr: rewardsAprAsNumber, lpTradeApr };
};

export default null;
