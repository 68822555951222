import { Flex, Text } from '@ballena-io/ballena-uikit';
import styled, { keyframes } from 'styled-components';

export const AccentGradient = keyframes`
0% {
  background-position: 50% 0%;
}
50% {
  background-position: 50% 100%;
}
100% {
  background-position: 50% 0%;
}
`;

export const StyledCardAccent = styled.div`
  background: ${({ theme }) => `linear-gradient(180deg, ${theme.colors.primary}, ${theme.colors.card})`};
  background-size: 400% 400%;
  animation: ${AccentGradient} 3s linear infinite;
  border-radius: 12px;
  filter: blur(2px);
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
`;

export const FCard = styled.div`
  align-self: baseline;
  background: ${(props) => props.theme.card.background};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 16px;
  position: relative;
  text-align: center;
`;

export const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 1px;
  margin: 16px auto;
  width: 100%;
`;

export const ExpandingWrapper = styled.div<{ expanded: boolean }>`
  height: ${(props) => (props.expanded ? '100%' : '0px')};
  overflow: hidden;
`;

export const WalletBalance = styled(Text)`
  font-size: 16px;
  text-align: left;
  padding: 6px 0;
`;

export const WalletBalanceTitle = styled(Flex)`
  padding-top: 12px;
`;
