import { AutoRenewIcon, Button, Flex, Text } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import UnlockButton from 'components/UnlockButton';
import { usePoolApprove } from 'hooks/useApprove';
import useI18n from 'hooks/useI18n';
import useWeb3 from 'hooks/useWeb3';
import React, { useCallback, useState } from 'react';
import { usePoolUser } from 'state/hooks';
import { Pool } from 'state/types';
import styled from 'styled-components';
import { getAddress } from 'utils/addressHelpers';
import { getBep20Contract } from 'utils/contractHelpers';
import { provider as ProviderType } from 'web3-core';

import HarvestAction from './HarvestAction';
import StakeAction from './StakeAction';

const Action = styled.div`
  padding-top: 16px;
`;

export interface PoolWithStakedValue extends Pool {
  apr?: number;
  tokenUsdPrice?: number;
}

interface PoolCardActionsProps {
  pool: PoolWithStakedValue;
  provider?: ProviderType;
  account?: string;
  getTokenUrl?: string;
  walletTokenBalance: BigNumber;
}

const CardActions: React.FC<PoolCardActionsProps> = ({ pool, account, getTokenUrl, walletTokenBalance }) => {
  const TranslateString = useI18n();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const { allowance, stakedBalance, earnings } = usePoolUser(pool.pid);
  const poolAddress = getAddress(pool.poolAddresses);
  const tokenAddress = getAddress(pool.stakingToken.address);
  const isApproved = account && allowance && allowance.isGreaterThan(0);
  const web3 = useWeb3();

  const tokenContract = getBep20Contract(tokenAddress, web3);

  const { onApprove } = usePoolApprove(tokenContract, poolAddress);

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      await onApprove();
      setRequestedApproval(false);
    } catch (e) {
      console.error(e);
    }
  }, [onApprove]);

  const renderApprovalOrStakeButton = () => {
    return isApproved ? (
      <StakeAction
        pid={pool.pid}
        stakedBalance={stakedBalance}
        walletTokenBalance={walletTokenBalance}
        tokenSymbol={pool.stakingToken.symbol}
        getTokenUrl={getTokenUrl}
        tokenUsdPrice={pool.tokenUsdPrice}
        poolState={pool.poolState}
      />
    ) : (
      <Button
        mt="8px"
        width="100%"
        disabled={requestedApproval}
        endIcon={requestedApproval ? <AutoRenewIcon spin color="primary" /> : null}
        onClick={handleApprove}
      >
        {TranslateString(870, 'Approve Contract')}
      </Button>
    );
  };

  return (
    <Action>
      <Flex>
        <Text bold textTransform="uppercase" color="secondary" fontSize="14px" pr="3px">
          BALLE
        </Text>
        <Text bold textTransform="uppercase" color="secondary" fontSize="14px">
          {TranslateString(946, 'Reward')}
        </Text>
      </Flex>
      <HarvestAction earnings={earnings} pid={pool.pid} />

      <Flex>
        <Text bold textTransform="uppercase" color="secondary" fontSize="14px" pr="3px">
          {pool.stakingToken.symbol}
        </Text>
        <Text bold textTransform="uppercase" color="secondary" fontSize="14px">
          {TranslateString(948, 'Deposited')}
        </Text>
      </Flex>
      {!account ? <UnlockButton mt="8px" width="100%" /> : renderApprovalOrStakeButton()}
    </Action>
  );
};

export default CardActions;
