import BigNumber from 'bignumber.js';
import { PoolConfig, VaultConfig } from 'config/constants/types';

export type TranslatableText =
  | string
  | {
      id: number;
      fallback: string;
      data?: {
        [key: string]: string | number;
      };
    };

export enum VaultStateEnum {
  ACTIVE = 'active',
  NEW = 'new',
  RETIRED = 'retired',
  PAUSED = 'paused',
}

export enum PoolStateEnum {
  ACTIVE = 'active',
  FINISHED = 'finished',
}
export interface Vault extends VaultConfig {
  depositTokenAmount?: BigNumber;
  farmPoolWeight?: BigNumber;
  farmRewardPerBlock?: BigNumber;
  farmTotalInQuoteToken?: BigNumber;
  lpTotalInQuoteToken?: BigNumber;
  multiplier?: string;
  quoteTokenAmount?: BigNumber;
  tokenAmount?: BigNumber;
  tokenPriceVsQuote?: BigNumber;
  userData?: {
    allowance: BigNumber;
    tokenBalance: BigNumber;
    stakedBalance: BigNumber;
    earnings: BigNumber;
    walletTokenBalance: BigNumber;
  };
  vaultState?: VaultStateEnum;
  vaultWeight?: BigNumber;
}

export interface Pool extends PoolConfig {
  stakedTokenAmount?: BigNumber;
  poolState?: PoolStateEnum;
  rewardStartBlock?: number;
  rewardEndBlock?: number;
  rewardPerBlock?: BigNumber;
  rewardActive?: boolean;
  multiplier?: string;
  userData?: {
    allowance: BigNumber;
    stakedBalance: BigNumber;
    earnings: BigNumber;
    walletTokenBalance: BigNumber;
  };
}

// Slices states

export interface VaultsState {
  data: Vault[];
}

export interface PoolsState {
  data: Pool[];
}

// API Price State
export interface PriceList {
  [key: string]: number;
}

export interface PriceApiResponse {
  /* eslint-disable camelcase */
  update_at: string;
  prices: PriceList;
}

export interface PriceState {
  isLoading: boolean;
  lastUpdated: string;
  data: PriceList;
}

// Block

export interface Block {
  blockNumber: number;
}

// BlockchainPrices

export interface BlockchainPrices {
  busd: number;
  balle: number;
  wbnb: number;
  cake: number;
}

// Global state

export interface State {
  pools: PoolsState;
  vaults: VaultsState;
  prices: PriceState;
  block: Block;
  blockchainPrices: BlockchainPrices;
}
