import { Image, Text } from '@ballena-io/ballena-uikit';
import styled from 'styled-components';

export const Separator = styled.div`
  margin-bottom: 8px;
`;

export const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
  padding: 16px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 32px 0;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  ${Text} {
    margin-left: 8px;
  }
`;

export const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px 32px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`;

export const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`;

export const StyledImage = styled(Image)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 58px;
`;
