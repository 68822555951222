import { Flex, Skeleton, Text } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import useI18n from 'hooks/useI18n';
import React, { useEffect, useState } from 'react';
import { useBlock } from 'state/hooks';
import styled from 'styled-components';
import { BIG_TEN_E18 } from 'utils/bigNumber';

const StyledBalleDistributionDisplay = styled.div`
  display: flex;
  flex; 1;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
  padding-top: 8px;
`;

const BALLE_REWARDS_STARTING_BLOCK = 7779016;
const BALLE_REWARDS_ENDING_BLOCK = 18291016;
const REWARDS_PER_BLOCK = new BigNumber('2283105022831050');

const VaultsBalleDistribution = () => {
  const TranslateString = useI18n();
  const blockchainBlock = useBlock();
  const [distributed, setDistributed] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const currentBlockNumber = blockchainBlock?.blockNumber;

  useEffect(() => {
    const balleDistributed = REWARDS_PER_BLOCK.times(new BigNumber(currentBlockNumber - BALLE_REWARDS_STARTING_BLOCK))
      .div(BIG_TEN_E18)
      .toNumber()
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
    setDistributed(balleDistributed);

    const balleRemaining = REWARDS_PER_BLOCK.times(new BigNumber(BALLE_REWARDS_ENDING_BLOCK - currentBlockNumber))
      .div(BIG_TEN_E18)
      .toNumber()
      .toLocaleString(undefined, { maximumFractionDigits: 2 });
    setRemaining(balleRemaining);
  }, [currentBlockNumber]);

  return (
    <StyledBalleDistributionDisplay>
      <Flex>
        <Text color="primary" fontSize="16px">
          {`${TranslateString(1062, 'Governance token distribution in Vaults')}: `}
        </Text>
        <Text bold color="text" fontSize="16px" ml="8px">
          24,000 BALLE
        </Text>
      </Flex>

      <Flex>
        <Text color="primary" fontSize="16px" mr="8px">
          {`${TranslateString(1064, 'Distributed Rewards')}: `}
        </Text>
        {currentBlockNumber > 0 && distributed ? (
          <Text bold color="text" fontSize="16px">
            {distributed} BALLE
          </Text>
        ) : (
          <Skeleton height={8} width={100} />
        )}
      </Flex>

      <Flex>
        <Text color="primary" fontSize="16px" mr="8px">
          {`${TranslateString(1066, 'Remaining Rewards')}: `}
        </Text>
        {currentBlockNumber > 0 && remaining ? (
          <Text bold color="text" fontSize="16px">
            {remaining} BALLE
          </Text>
        ) : (
          <Skeleton height={16} width={100} />
        )}
      </Flex>
    </StyledBalleDistributionDisplay>
  );
};

export default VaultsBalleDistribution;
