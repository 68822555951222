export const EN = { code: 'en', language: 'English' };
// export const AR = { code: 'ar', language: 'العربية' }
export const CA = { code: 'ca', language: 'Català' };
export const ZHCN = { code: 'zh-CN', language: '简体中文' };
// export const ZHTW = { code: 'zh-TW', language: '繁體中文' }
// export const CS = { code: 'cs', language: 'Čeština' }
// export const DA = { code: 'da', language: 'Dansk' }
// export const DE = { code: 'de', language: 'Deutsch' }
// export const NL = { code: 'nl', language: 'Nederlands' }
// export const FIL = { code: 'fil', language: 'Filipino' }
// export const FI = { code: 'fi', language: 'Suomalainen' }
export const FR = { code: 'fr', language: 'Français' };
// export const EL = { code: 'el', language: 'Ελληνικά' }
// export const HI = { code: 'hi', language: 'हिंदी' }
// export const HU = { code: 'hu', language: 'Magyar' }
// export const ID = { code: 'id', language: 'Bahasa Indonesia' }
// export const IT = { code: 'it', language: 'Italiano' };
// export const JA = { code: 'ja', language: '日本語' }
// export const KO = { code: 'ko', language: '한국어' }
// export const PTBR = { code: 'pt-BR', language: 'Português' }
// export const RO = { code: 'ro', language: 'Română' }
export const RU = { code: 'ru', language: 'Русский' };
// export const SR = { code: 'sr', language: 'Српски' }
export const ESES = { code: 'es-ES', language: 'Español' };
// export const SVSE = { code: 'sv-SE', language: 'Svenska' }
// export const TA = { code: 'ta', language: 'தமிழ்' }
// export const TR = { code: 'tr', language: 'Türkçe' }
// export const UK = { code: 'uk', language: 'Українська' }
// export const VI = { code: 'vi', language: 'Tiếng Việt' }

export const allLanguages = [EN, ESES, FR, CA, ZHCN, RU];
