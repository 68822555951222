import { getAddress } from 'utils/addressHelpers';

import tokens from './tokens';
import { PoolConfig } from './types';

const pools: PoolConfig[] = [
  {
    pid: 0,
    poolAddresses: {
      97: '0x05CEf85E2f44941D62220527C50c1bE7cd779A39',
      56: '0x09d5040CC85A93A72E99B1A39c48801467c5b732',
    },
    stakingToken: tokens.balle,
    rewardToken: tokens.balle,
  },
];
// We filter vaults with no deposit Token address (for testing) to avoid crash
const filteredPoolsConfig = pools.filter((poolConfig) => getAddress(poolConfig.poolAddresses) !== '');
export default filteredPoolsConfig;
