import { AutoRenewIcon, Button, Flex, Text } from '@ballena-io/ballena-uikit';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { useStakingHarvest } from 'hooks/useStakingHarvest';
import useI18n from 'hooks/useI18n';
import useToast from 'hooks/useToast';
import React, { useEffect, useRef, useState } from 'react';
import { useCountUp } from 'react-countup';
import { usePoolFromPid, usePriceBalleBusd } from 'state/hooks';
import styled from 'styled-components';
import { getBalanceNumber, isDustBalance } from 'utils/formatBalance';
import { getAddress } from 'utils/addressHelpers';

export const UsdPrice = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Amounts = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
`;

interface PoolCardActionsProps {
  pid?: number;
  earnings?: BigNumber;
}

const DECIMALS_NUMBER = 6;

const HarvestAction: React.FC<PoolCardActionsProps> = ({ pid, earnings }) => {
  const TranslateString = useI18n();
  const { account } = useWeb3React();
  const [pendingTx, setPendingTx] = useState(false);
  const { poolAddresses } = usePoolFromPid(pid);
  const poolAddress = getAddress(poolAddresses);
  const { onReward } = useStakingHarvest(poolAddress);
  const { toastSuccess, toastError } = useToast();

  const ballePrice = usePriceBalleBusd();
  let balancedEarnings = null;
  let earningsBusd = 0;
  let displayBalance = '?';

  const rawEarningsBalance = account ? getBalanceNumber(earnings) : 0;
  if (earnings) {
    balancedEarnings = getBalanceNumber(earnings);
    earningsBusd = new BigNumber(balancedEarnings).multipliedBy(ballePrice).toNumber();
    displayBalance = rawEarningsBalance.toLocaleString(undefined, { minimumFractionDigits: DECIMALS_NUMBER });
  }

  const { countUp, update } = useCountUp({
    start: 0,
    end: earningsBusd,
    duration: 1,
    separator: ',',
    decimals: 2,
  });
  const updateValue = useRef(update);

  useEffect(() => {
    updateValue.current(earningsBusd);
  }, [earningsBusd, updateValue]);

  const showToastError = () =>
    toastError(TranslateString(966, 'Canceled'), TranslateString(968, 'Please try again and confirm the transaction.'));

  const handleHarvestClick = async () => {
    try {
      setPendingTx(true);
      const Tx = await onReward();

      if (Tx && Tx.status === true) {
        toastSuccess(
          `${TranslateString(752, 'Harvest')}!`,
          `${TranslateString(1014, 'Your BALLE rewards were sent to your wallet!')}`,
        );
        setPendingTx(false);
      }

      if (!Tx) {
        showToastError();
        setPendingTx(false);
      }
    } catch (e) {
      showToastError();
      console.error(e);
    }
  };

  return (
    <Flex mb="8px" justifyContent="space-between" alignItems="center">
      <Amounts>
        <Text color={isDustBalance(earnings) ? 'textDisabled' : 'text'} fontSize="24px">
          {displayBalance}
        </Text>
        <UsdPrice>~{countUp}USD</UsdPrice>
      </Amounts>
      <Button
        variant="secondary"
        disabled={isDustBalance(earnings) || pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="primary" /> : null}
        onClick={handleHarvestClick}
      >
        {TranslateString(752, 'Harvest')}
      </Button>
    </Flex>
  );
};

export default HarvestAction;
