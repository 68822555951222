import BigNumber from 'bignumber.js';
import balleStakingPoolABI from 'config/abi/balleStakingPool.json';
import poolsConfig from 'config/constants/pools';
import { PoolStateEnum } from 'state/types';
import { getAddress } from 'utils/addressHelpers';
import { BIG_TEN_E18 } from 'utils/bigNumber';
import multicall from 'utils/multicall';
import { getWeb3NoAccount } from 'utils/web3';

const fetchPools = async () => {
  const data = await Promise.all(
    poolsConfig.map(async (poolConfig) => {
      const poolAddress = getAddress(poolConfig.poolAddresses);
      const [totalSupply, startBlock, endBlock, finished, rewardPerBlock, multiplier] = await multicall(
        balleStakingPoolABI,
        [
          {
            address: poolAddress,
            name: 'totalSupply',
          },
          {
            address: poolAddress,
            name: 'rewardStartBlock',
          },
          {
            address: poolAddress,
            name: 'rewardEndBlock',
          },
          {
            address: poolAddress,
            name: 'finished',
          },
          {
            address: poolAddress,
            name: 'rewardPerBlock',
          },
          {
            address: poolAddress,
            name: 'extraRewardMultiplier',
          },
        ],
      );

      const poolState = finished[0] === true ? PoolStateEnum.FINISHED : PoolStateEnum.ACTIVE;

      const web3 = getWeb3NoAccount();
      const currentBlockNumber = await web3.eth.getBlockNumber();
      const rewardStartBlock = new BigNumber(startBlock).toNumber();
      const rewardEndBlock = new BigNumber(endBlock).toNumber();
      const rewardActive = currentBlockNumber >= rewardStartBlock && currentBlockNumber <= rewardEndBlock;

      return {
        ...poolConfig,
        stakedTokenAmount: new BigNumber(totalSupply).toJSON(),
        poolState,
        rewardStartBlock,
        rewardEndBlock,
        rewardActive,
        rewardPerBlock: new BigNumber(rewardPerBlock).div(BIG_TEN_E18).toJSON(),
        multiplier: `${new BigNumber(multiplier).div(100).toString()}X`,
      };
    }),
  );
  return data;
};

export default fetchPools;
