import { AutoRenewIcon, Button } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import useI18n from 'hooks/useI18n';
import useToast from 'hooks/useToast';
import { useUnstakeAll } from 'hooks/useUnstake';
import React, { useState } from 'react';
import { isDustBalance } from 'utils/formatBalance';
import { usePoolFromPid } from 'state/hooks';
import { getAddress } from 'utils/addressHelpers';

interface WithdrawAllButtonProps {
  pid: number;
  tokenBalance: BigNumber;
  tokenSymbol: string;
}

const WithdrawAllButton: React.FC<WithdrawAllButtonProps> = ({ pid, tokenBalance, tokenSymbol }) => {
  const TranslateString = useI18n();
  const { poolAddresses } = usePoolFromPid(pid);
  const poolAddress = getAddress(poolAddresses);
  const { onUnstakeAll } = useUnstakeAll(poolAddress);
  const { toastSuccess, toastError } = useToast();

  const [pendingTx, setPendingTx] = useState(false);

  const showToastError = () =>
    toastError(TranslateString(966, 'Canceled'), TranslateString(968, 'Please try again and confirm the transaction.'));

  const handleUnstakeAll = async () => {
    setPendingTx(true);

    try {
      const Tx = await onUnstakeAll();

      if (!Tx) {
        setPendingTx(false);
        showToastError();
      }
      if (Tx && Tx.status === true) {
        toastSuccess(
          TranslateString(962, 'Withdrawn'),
          `${tokenSymbol} ${TranslateString(964, 'and BALLE rewards have been sent to your wallet!')}`,
        );
        setPendingTx(false);
      }
    } catch (e) {
      showToastError();
      setPendingTx(false);
    }
  };

  return (
    <Button
      onClick={handleUnstakeAll}
      variant="secondary"
      isLoading={pendingTx}
      disabled={isDustBalance(tokenBalance) || pendingTx}
      endIcon={pendingTx ? <AutoRenewIcon spin color="primary" /> : null}
    >
      {TranslateString(5383, 'Withdraw All')}
    </Button>
  );
};

export default WithdrawAllButton;
