import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPoolUserDataAsync } from 'state/actions';
import { stakingHarvest } from 'utils/callHelpers';

import { useStakingPool } from './useContract';

export const useStakingHarvest = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const poolContract = useStakingPool(poolAddress);

  const handleHarvest = useCallback(async () => {
    try {
      const txHash = await stakingHarvest(poolContract, account);
      dispatch(fetchPoolUserDataAsync(account));
      return txHash;
    } catch (e) {
      return false;
    }
  }, [account, dispatch, poolContract]);

  return { onReward: handleHarvest };
};

export default null;
