import { configureStore } from '@reduxjs/toolkit';

import blockReducer from './block';
import blockchainPricesReducer from './blockchainPrices';
import poolsReducer from './pools';
import pricesReducer from './prices';
import vaultsReducer from './vaults';

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    vaults: vaultsReducer,
    pools: poolsReducer,
    prices: pricesReducer,
    block: blockReducer,
    blockchainPrices: blockchainPricesReducer,
  },
});
