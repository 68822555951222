import balleMasterABI from 'config/abi/balleMaster.json';
import balleMigrationABI from 'config/abi/balleMigration.json';
import stakingPoolABI from 'config/abi/balleStakingPool.json';
import cakeAbi from 'config/abi/cake.json';
import claimRefundAbi from 'config/abi/claimRefund.json';
import bep20Abi from 'config/abi/erc20.json';
import masterChef from 'config/abi/masterchef.json';
import {
  getBalleMasterAddress,
  getBalleMigrationAddress,
  getCakeAddress,
  getClaimRefundAddress,
  getMasterChefAddress,
} from 'utils/addressHelpers';
import web3NoAccount from 'utils/web3';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

// Addresses
// ABI
const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract((abi as unknown) as AbiItem, address);
};

export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3);
};

export const getBalleMigrationContract = (web3?: Web3) => {
  return getContract(balleMigrationABI, getBalleMigrationAddress(), web3);
};

export const getBalleMasterContract = (migration: boolean, web3?: Web3) => {
  return getContract(balleMasterABI, getBalleMasterAddress(migration), web3);
};

export const getStakingPoolContract = (address: string, web3?: Web3) => {
  return getContract(stakingPoolABI, address, web3);
};

// ----------------------------------------------

// TODO:  Check if we need these methods or delete them
export const getCakeContract = (web3?: Web3) => {
  return getContract(cakeAbi, getCakeAddress(), web3);
};
export const getMasterchefContract = (web3?: Web3) => {
  return getContract(masterChef, getMasterChefAddress(), web3);
};
export const getClaimRefundContract = (web3?: Web3) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), web3);
};
