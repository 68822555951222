import { Flex, Link, LinkExternal, Text, TooltipText, useTooltip } from '@ballena-io/ballena-uikit';
import useI18n from 'hooks/useI18n';
import React from 'react';
import styled from 'styled-components';

export interface ExpandableSectionProps {
  poolContractBscScanAddress?: string;
  tokenLabel?: string;
  getTokenUrl: string;
  tokenUsdPrice?: number;
}

const Wrapper = styled.div`
  margin-top: 24px;
`;

const StyledLinkExternal = styled(LinkExternal)`
  text-decoration: none;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  align-items: center;

  svg {
    padding-left: 4px;
    height: 18px;
    width: auto;
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const DetailsSection: React.FC<ExpandableSectionProps> = ({
  poolContractBscScanAddress,
  tokenLabel,
  getTokenUrl,
  tokenUsdPrice,
}) => {
  const TranslateString = useI18n();
  const formattedLpUsdPrice = tokenUsdPrice
    ? tokenUsdPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : '';

  const LpPriceTooltipContent = TranslateString(1008, 'The estimated value of one token, in American dollars');
  const { targetRef, tooltip, tooltipVisible } = useTooltip(LpPriceTooltipContent, { placement: 'bottom-start' });

  return (
    <Wrapper>
      <Flex justifyContent="space-between">
        <Text>{TranslateString(538, 'Deposit')}:</Text>
        <StyledLinkExternal href={getTokenUrl}>{tokenLabel}</StyledLinkExternal>
      </Flex>

      {tooltipVisible && tooltip}
      <Flex justifyContent="space-between">
        <TooltipText ref={targetRef}>1 x {tokenLabel}:</TooltipText>
        <Text>~{formattedLpUsdPrice}USD</Text>
      </Flex>
      <Flex justifyContent="flex-start">
        <Link external href={poolContractBscScanAddress} bold={false}>
          {TranslateString(1098, 'Pool Contract')}
        </Link>
      </Flex>
      <Text fontSize="14px" pt="8px">
        {TranslateString(976, 'Deposit/Withdraw will automatically claim rewards for you')}
      </Text>
    </Wrapper>
  );
};

export default DetailsSection;
