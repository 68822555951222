import { ChevronDownIcon, ChevronUpIcon, Text } from '@ballena-io/ballena-uikit';
import React from 'react';
import styled from 'styled-components';
import useI18n from 'hooks/useI18n';

export interface ExpandableSectionButtonProps {
  onClick?: () => void;
  expanded?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: right;
  justify-content: flex-end;
  cursor: pointer;
  padding-top: 20px;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

const ExpandableSectionButton: React.FC<ExpandableSectionButtonProps> = ({ onClick, expanded }) => {
  const TranslateString = useI18n();

  return (
    <Wrapper aria-label="Hide or show expandable content" role="button" onClick={() => onClick()}>
      <Text color="primary" bold>
        {expanded ? TranslateString(874, 'Hide') : TranslateString(848, 'Details')}
      </Text>
      {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </Wrapper>
  );
};

ExpandableSectionButton.defaultProps = {
  expanded: false,
};

export default ExpandableSectionButton;
