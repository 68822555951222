import BigNumber from 'bignumber.js';
import useRefresh from 'hooks/useRefresh';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWeb3NoAccount } from 'utils/web3';

import {
  fetchBallePriceAsync,
  fetchCakePriceAsync,
  fetchVaultsPublicDataAsync,
  fetchWbnbPriceAsync,
  setBlock,
  fetchPoolsPublicDataAsync,
} from './actions';
import { fetchPrices } from './prices';
import { Block, BlockchainPrices, Pool, PriceState, State, Vault } from './types';

export const useFetchPublicData = () => {
  const dispatch = useDispatch();
  const { slowRefresh } = useRefresh();
  useEffect(() => {
    dispatch(fetchPoolsPublicDataAsync());
    dispatch(fetchVaultsPublicDataAsync());
    dispatch(fetchBallePriceAsync());
    dispatch(fetchWbnbPriceAsync());
    dispatch(fetchCakePriceAsync());
  }, [dispatch, slowRefresh]);

  useEffect(() => {
    const web3 = getWeb3NoAccount();
    const interval = setInterval(async () => {
      const blockNumber = await web3.eth.getBlockNumber();
      dispatch(setBlock(blockNumber));
    }, 6000);

    return () => clearInterval(interval);
  }, [dispatch]);
};

// Vaults

export const useVaults = (): Vault[] => {
  const vaults = useSelector((state: State) => state.vaults.data);

  return vaults;
};

export const useVaultFromVid = (vid: number, migration: boolean): Vault => {
  const vault = useSelector((state: State) =>
    state.vaults.data.find((f) => f.vid === vid && f.migration === migration),
  );

  return vault;
};

export const useVaultFromSymbol = (depositTokenSymbol: string, migration: boolean): Vault => {
  const vault = useSelector((state: State) =>
    state.vaults.data.find((f) => f.depositTokenSymbol === depositTokenSymbol && f.migration === migration),
  );

  return vault;
};

export const useVaultUser = (vid: number, migration: boolean) => {
  const vault = useVaultFromVid(vid, migration);

  return {
    allowance: vault.userData ? new BigNumber(vault.userData.allowance) : new BigNumber(0),
    // Total Token amount deposited + yield in Vault
    tokenBalance: vault.userData ? new BigNumber(vault.userData.tokenBalance) : new BigNumber(0),
    // Token initial deposit in Vault
    stakedBalance: vault.userData ? new BigNumber(vault.userData.stakedBalance) : new BigNumber(0),
    // Rewards earned
    earnings: vault.userData ? new BigNumber(vault.userData.earnings) : new BigNumber(0),
    walletTokenBalance: vault.userData ? new BigNumber(vault.userData.walletTokenBalance) : new BigNumber(0),
  };
};

// Pools
export const usePools = (): Pool[] => {
  const pools = useSelector((state: State) => state.pools.data);
  return pools;
};

export const usePoolFromPid = (pid: number): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((f) => f.pid === pid));

  return pool;
};

export const usePoolUser = (pid: number) => {
  const pool = usePoolFromPid(pid);

  return {
    allowance: pool.userData ? new BigNumber(pool.userData.allowance) : new BigNumber(0),
    // Token initial deposit in Pool
    stakedBalance: pool.userData ? new BigNumber(pool.userData.stakedBalance) : new BigNumber(0),
    // Rewards earned
    earnings: pool.userData ? new BigNumber(pool.userData.earnings) : new BigNumber(0),
    walletTokenBalance: pool.userData ? new BigNumber(pool.userData.walletTokenBalance) : new BigNumber(0),
  };
};

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPrices());
  }, [dispatch, slowRefresh]);
};

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data);
  return prices;
};

export const useGetApiPrice = (token: string) => {
  const prices = useGetApiPrices();

  if (!prices) {
    return null;
  }

  return prices[token.toLowerCase()];
};

export const usePriceCakeBusd = (): number => {
  const cakeBusd = useSelector((state: State) => state.blockchainPrices.cake);
  return cakeBusd;
};

export const usePriceBalleBusd = (): number => {
  const balleBusd = useSelector((state: State) => state.blockchainPrices.balle);
  return balleBusd;
};

export const usePriceWbnbBusd = (): number => {
  const wbnbBusd = useSelector((state: State) => state.blockchainPrices.wbnb);
  return wbnbBusd;
};

export const useBlockchainPrices = (): BlockchainPrices => {
  const blockchainPrices = useSelector((state: State) => state.blockchainPrices);
  return blockchainPrices;
};

// Block
export const useBlock = (): Block => {
  return useSelector((state: State) => state.block);
};
