import { ModalProvider } from '@ballena-io/ballena-uikit';
import { Web3ReactProvider } from '@web3-react/core';
import { LanguageContextProvider } from 'contexts/Localisation/languageContext';
import { RefreshContextProvider } from 'contexts/RefreshContext';
import { ThemeContextProvider } from 'contexts/ThemeContext';
import { ToastsProvider } from 'contexts/ToastsContext';
import React from 'react';
import { Provider } from 'react-redux';
import store from 'state';
import { getLibrary } from 'utils/web3React';

const Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastsProvider>
          <ThemeContextProvider>
            <LanguageContextProvider>
              <RefreshContextProvider>
                <ModalProvider>{children}</ModalProvider>
              </RefreshContextProvider>
            </LanguageContextProvider>
          </ThemeContextProvider>
        </ToastsProvider>
      </Provider>
    </Web3ReactProvider>
  );
};

export default Providers;
