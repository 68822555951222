import { BASE_BSC_SCAN_URL } from 'config';

const TEST_NET_BSCSCAN_URL = 'https://testnet.bscscan.com';
const BSCSCAN_URLS = {
  97: TEST_NET_BSCSCAN_URL,
  56: BASE_BSC_SCAN_URL,
};

export const getBscScanAddressUrl = (address: string) => {
  return `${BASE_BSC_SCAN_URL}/address/${address}`;
};

export const getBscScanTransactionUrl = (transactionHash: string) => {
  return `${BASE_BSC_SCAN_URL}/tx/${transactionHash}`;
};

export const getBscScanBlockNumberUrl = (block: string | number) => {
  return `${BASE_BSC_SCAN_URL}/block/${block}`;
};

export const getBscScanBlockCountdownUrl = (block: string | number) => {
  const chainId = process.env.REACT_APP_CHAIN_ID;

  return `${BSCSCAN_URLS[chainId]}/block/countdown/${block}`;
};
