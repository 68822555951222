export const sendTransferTokensEvent = (action: string, label: string, value: string) => {
  return (window as any).dataLayer.push({
    event: 'event',
    eventProps: {
      category: 'tokens-transfer',
      action,
      label,
      value,
    },
  });
};

export default {};
