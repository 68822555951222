import { MenuEntry } from '@ballena-io/ballena-uikit';

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Vaults',
    icon: 'VaultIcon',
    href: '/vaults',
  },
  {
    label: 'Pools',
    icon: 'StakingIcon',
    href: '/pools',
  },
  {
    label: 'Docs',
    icon: 'DocsIcon',
    href: 'https://docs.ballena.io',
    external: true,
  },
  {
    label: 'Vote',
    icon: 'VoteIcon',
    href: 'https://vote.ballena.io',
    external: true,
  },
  {
    label: 'Audits',
    icon: 'VerifiedIcon',
    href: 'https://github.com/ballena-io/ballena-audits',
    external: true,
  },
];

export default config;
