const TEST_NET_BSCSCAN_URL = 'https://testnet.bscscan.com/address/';
const MAIN_NET_BSCSCAN_URL = 'https://bscscan.com/address/';
const BSCSCAN_URLS = {
  97: TEST_NET_BSCSCAN_URL,
  56: MAIN_NET_BSCSCAN_URL,
};

const getBlockExplorerUrl = (addresses): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  const bscScanAddress = `${BSCSCAN_URLS[chainId]}${addresses[chainId]}`;

  return bscScanAddress;
};

export default getBlockExplorerUrl;
