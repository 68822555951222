import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useBlockchainPrices, usePools, useVaults } from 'state/hooks';
import { BlockchainPrices, Pool, Vault } from 'state/types';
import { BIG_TEN_E18 } from 'utils/bigNumber';

import useRefresh from './useRefresh';

export const getVaultTvl = (vault: Vault, blockchainPrices: BlockchainPrices): BigNumber => {
  const quoteTokenPriceUsd = blockchainPrices[vault.quoteToken.symbol.toLowerCase()];
  const vaultTvl = new BigNumber(vault.lpTotalInQuoteToken).times(quoteTokenPriceUsd);

  return vaultTvl;
};

export const getPoolTvl = (pool: Pool, blockchainPrices: BlockchainPrices): BigNumber => {
  const stakingTokenPriceUsd = blockchainPrices[pool.stakingToken.symbol.toLowerCase()];
  const poolTvl = new BigNumber(pool.stakedTokenAmount).div(BIG_TEN_E18).times(stakingTokenPriceUsd);

  return poolTvl;
};

const useTvl = () => {
  const vaultsLP = useVaults();
  const pools = usePools();
  const blockchainPrices = useBlockchainPrices();
  const [tvlSum, setTvlSum] = useState(0);
  const [vaultsTVL, setVaultsTvl] = useState(0);
  const [poolsTVL, setPoolsTvl] = useState(0);
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    const vaultsTvlSum = vaultsLP.reduce((accum, vault) => {
      const vaultTvl = getVaultTvl(vault, blockchainPrices);

      return accum + vaultTvl.toNumber();
    }, 0);

    setVaultsTvl(vaultsTvlSum);
  }, [blockchainPrices, vaultsLP, slowRefresh]);

  useEffect(() => {
    const poolsTvlSum = pools.reduce((accum, pool) => {
      const poolTvl = getPoolTvl(pool, blockchainPrices);

      return accum + poolTvl.toNumber();
    }, 0);

    setPoolsTvl(poolsTvlSum);
  }, [blockchainPrices, pools, slowRefresh]);

  useEffect(() => {
    setTvlSum(poolsTVL + vaultsTVL);
  }, [poolsTVL, vaultsTVL]);

  return { tvlSum, poolsTVL, vaultsTVL };
};

export default useTvl;
