import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPoolUserDataAsync } from 'state/actions';
import { stake, stakeAll } from 'utils/callHelpers';

import { useStakingPool } from './useContract';

export const useStakeAll = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const poolContract = useStakingPool(poolAddress);

  const handleStakeAll = useCallback(async () => {
    try {
      const txHash = await stakeAll(poolContract, account);
      dispatch(fetchPoolUserDataAsync(account));
      console.info(txHash);
      return txHash;
    } catch (e) {
      return false;
    }
  }, [account, dispatch, poolContract]);

  return { onStakeAll: handleStakeAll };
};

export const useStake = (poolAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const poolContract = useStakingPool(poolAddress);

  const handleStake = useCallback(
    async (amount: string) => {
      try {
        const txHash = await stake(poolContract, amount, account);
        dispatch(fetchPoolUserDataAsync(account));
        console.info(txHash);
        return txHash;
      } catch (e) {
        return false;
      }
    },
    [account, dispatch, poolContract],
  );

  return { onStake: handleStake };
};

export default null;
