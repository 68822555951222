import BigNumber from 'bignumber.js';
import { getBlocksPerYear } from 'config/blockchain';
import { getAddress } from 'utils/addressHelpers';

import { Vault } from '../../../state/types';
import { FarmPoolData } from '../types';
import { BIG_ZERO } from '../../bigNumber';
import lpTradeAprs from '../pancake/lpTradeAprs.json';

const blocksPerYear = getBlocksPerYear();

/**
 * Get farm data
 * @param vault Vault to get data from (the underlying farm)
 * @returns
 */
export const getBallenaFarmData = async (): Promise<FarmPoolData> => {
  return {
    farmPoolWeight: BIG_ZERO,
    farmRewardPerBlock: BIG_ZERO,
  };
};

/**
 * Get farm APR value in %
 * @param vault Vault to calculate APR
 * @param ballePriceUsd BALLE price in USD
 * @param quoteTokenPriceUsd Quote token price in USD
 * @returns
 */
export const getBallenaFarmApr = (
  vault: Vault,
  ballePriceUsd: number,
  quoteTokenPriceUsd: number,
): { rewardsApr: number; lpTradeApr: number } => {
  const totalLiquidity = new BigNumber(vault.farmTotalInQuoteToken).times(quoteTokenPriceUsd);
  const yearlyRewardAllocation = new BigNumber(vault.farmRewardPerBlock)
    .times(blocksPerYear)
    .times(vault.farmPoolWeight);
  const rewardsApr = yearlyRewardAllocation.times(ballePriceUsd).div(totalLiquidity).times(100);
  let rewardsAprAsNumber = null;
  if (!rewardsApr.isNaN() && rewardsApr.isFinite()) {
    rewardsAprAsNumber = rewardsApr.toNumber();
  }
  const lpTradeApr = lpTradeAprs[getAddress(vault.depositTokenAddresses).toLocaleLowerCase()] ?? 0;
  return { rewardsApr: rewardsAprAsNumber, lpTradeApr };
};

export default null;
