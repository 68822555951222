import { Flex, Link, Skeleton, Text, TimerIcon } from '@ballena-io/ballena-uikit';
import { BSC_BLOCK_TIME } from 'config';
import { intervalToDuration } from 'date-fns';
import useI18n from 'hooks/useI18n';
import React from 'react';
import { useBlock } from 'state/hooks';
import { Pool } from 'state/types';
import { getBscScanBlockCountdownUrl } from 'utils/bscscan';
import { getPoolBlockInfo } from 'views/Pools/helpers';

interface PoolCountdownProps {
  pool: Pool;
}

const formatSecondsToDate = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const formatted = `${duration.days}d:${duration.hours}h:${duration.minutes}m`;

  return formatted;
};

const PoolCountdown: React.FC<PoolCountdownProps> = ({ pool }) => {
  const TranslateString = useI18n();
  const { rewardEndBlock, rewardStartBlock } = pool;
  const blockchainBlock = useBlock();
  const currentBlock = blockchainBlock?.blockNumber;

  const {
    shouldShowBlockCountdown,
    blocksUntilStart,
    blocksRemaining,
    hasPoolStarted,
    blocksToDisplay,
  } = getPoolBlockInfo(pool, currentBlock);

  const blocksToSeconds = blocksToDisplay * BSC_BLOCK_TIME;
  const formattedSeconds = formatSecondsToDate(blocksToSeconds);

  const renderBlocks = shouldShowBlockCountdown ? (
    <Flex alignItems="center">
      <Flex>
        <Link
          external
          href={getBscScanBlockCountdownUrl(hasPoolStarted ? rewardEndBlock : rewardStartBlock)}
          onClick={(e) => e.stopPropagation()}
        >
          <TimerIcon mr="4px" />
        </Link>
      </Flex>
      <Flex>
        <Text bold>{formattedSeconds}</Text>
      </Flex>
    </Flex>
  ) : (
    <Text>-</Text>
  );

  const isLoadingPublicData = !currentBlock || (!blocksRemaining && !blocksUntilStart);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text>
        {currentBlock < rewardStartBlock && TranslateString(1100, 'Starts in')}
        {currentBlock > rewardStartBlock && currentBlock < rewardEndBlock && TranslateString(1102, 'Ends in')}
        {currentBlock > rewardEndBlock && TranslateString(1104, 'Waiting for rewards')}
      </Text>

      {isLoadingPublicData ? <Skeleton width="80px" height="16px" /> : renderBlocks}
    </Flex>
  );
};

export default PoolCountdown;
