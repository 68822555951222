import { ButtonMenu, ButtonMenuItem } from '@ballena-io/ballena-uikit';
import useI18n from 'hooks/useI18n';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 16px;
  }
`;

const PoolTabButtons = () => {
  const { url, isExact } = useRouteMatch();
  const TranslateString = useI18n();

  return (
    <Wrapper>
      <ButtonMenu activeIndex={isExact ? 0 : 1} scale="sm" variant="subtle">
        <ButtonMenuItem as={Link} to={`${url}`}>
          {TranslateString(892, 'Live')}
        </ButtonMenuItem>
        <ButtonMenuItem as={Link} to={`${url}/history`}>
          {TranslateString(594, 'Finished')}
        </ButtonMenuItem>
      </ButtonMenu>
    </Wrapper>
  );
};

export default PoolTabButtons;
