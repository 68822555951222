import { Menu as UikitMenu } from '@ballena-io/ballena-uikit';
import { useWeb3React } from '@web3-react/core';
import { allLanguages } from 'config/localisation/languageCodes';
import { LanguageContext } from 'contexts/Localisation/languageContext';
import useAuth from 'hooks/useAuth';
import useTheme from 'hooks/useTheme';
import React, { useContext } from 'react';
import { usePriceBalleBusd } from 'state/hooks';

import config from './config';

const Menu = (props) => {
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const { isDark, toggleTheme } = useTheme();
  const ballePriceUsd = usePriceBalleBusd();

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      ballePriceUsd={ballePriceUsd}
      links={config}
      {...props}
    />
  );
};

export default Menu;
