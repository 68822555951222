import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/erc20.json';
import balleStakingPoolABI from 'config/abi/balleStakingPool.json';
import poolsConfig from 'config/constants/pools';
import { getAddress } from 'utils/addressHelpers';
import multicall from 'utils/multicall';

export const fetchPoolUserAllowances = async (account: string) => {
  const calls = poolsConfig.map((pool) => {
    const tokenAddress = getAddress(pool.stakingToken.address);
    const poolAddress = getAddress(pool.poolAddresses);
    return {
      address: tokenAddress,
      name: 'allowance',
      params: [account, poolAddress],
    };
  });

  const rawAllowances = await multicall(erc20ABI, calls);
  const parsedAllowances = rawAllowances.map((balance) => {
    return new BigNumber(balance).toJSON();
  });
  return parsedAllowances;
};

export const fetchPoolUserStakedBalances = async (account: string) => {
  const calls = poolsConfig.map((pool) => {
    const poolAddress = getAddress(pool.poolAddresses);
    return {
      address: poolAddress,
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(balleStakingPoolABI, calls);
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchPoolUserRewards = async (account: string) => {
  const calls = poolsConfig.map((pool) => {
    const poolAddress = getAddress(pool.poolAddresses);
    return {
      address: poolAddress,
      name: 'pendingReward',
      params: [account],
    };
  });

  const rawRewards = await multicall(balleStakingPoolABI, calls);
  const parsedRewards = rawRewards.map((rewards) => {
    return new BigNumber(rewards).toJSON();
  });
  return parsedRewards;
};

export const fetchUserWalletPoolTokenBalances = async (account: string) => {
  const calls = poolsConfig.map((pool) => {
    const tokenAddress = getAddress(pool.stakingToken.address);
    return {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    };
  });

  const rawTokenBalances = await multicall(erc20ABI, calls);
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};
