import addresses from '../config/constants/contracts';
import tokens from '../config/constants/tokens';
import { Address } from '../config/constants/types';

export const getAddress = (address: Address): string => {
  const mainNetChainId = 56;
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return address[chainId] ? address[chainId] : address[mainNetChainId];
};

export const getBalleAddress = () => {
  return getAddress(tokens.balle.address);
};

export const getBalleMigrationAddress = () => {
  return getAddress(addresses.balleMigration);
};

export const getBalleMasterAddress = (migration) => {
  if (migration) {
    return getAddress(addresses.balleMasterMigration);
  }
  return getAddress(addresses.balleMaster);
};

export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall);
};

// ----------------------------------------------
export const getCakeAddress = () => {
  return getAddress(tokens.cake.address);
};
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef);
};
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address);
};
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile);
};
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits);
};
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory);
};
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund);
};
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial);
};
