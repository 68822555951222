export default {
  balleMigration: {
    97: '0xfe70697DF155C775aF35C7b6283aa905333312E7',
    56: '0x019e4abb7fd239f838d7ecbdd6601e0fcfcf497b',
  },
  balleMaster: {
    97: '0x943e160E966e35F15c8D964e1FE05E37867D5671',
    56: '0x1105bd1D8C952C24E73f6866177FAD7Bfb35e13D',
  },
  balleMasterMigration: {
    97: '0x0F99AB43b2356446014eaFb575Fe7d96BCF5C2Ae',
    56: '0x26FBb0FF7589A43C7d4B2Ff9A68A0519c474156c',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  balleWbnbLP: {
    97: '0x29089e9BdD77ac719Bcb94d6eE1ee34F2fEe8cf8',
    56: '0xa4C9418d4CE79cD2b5d64E08D91B7D00cEC40dC3',
  },
  wbnbBusdLP: {
    97: '0xe0e92035077c39594793e61802a350347c320cf2',
    56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  },
  cakeWbnbLP: {
    97: '0x8eb36f0272ac65b84b36aa1980d5f3ec46b008b4', // This is our (mocked testnet CAKE)/BNB working LP
    56: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  },
  // ------------------------------------------------
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
};
