import { Alert, Text, Toggle } from '@ballena-io/ballena-uikit';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import FlexLayout from 'components/layout/Flex';
import Page from 'components/layout/Page';
import PageHeader from 'components/PageHeader';
import { LanguageContext } from 'contexts/Localisation/languageContext';
import useI18n from 'hooks/useI18n';
import useRefresh from 'hooks/useRefresh';
import { getPoolTvl } from 'hooks/useTvl';
import { orderBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import { fetchPoolUserDataAsync } from 'state/actions';
import { useBlockchainPrices, usePools } from 'state/hooks';
import { Pool, PoolStateEnum } from 'state/types';
import getPoolApr from 'utils/apr';
import { BIG_TEN_E18 } from 'utils/bigNumber';
import { isDustBalance } from 'utils/formatBalance';
import getLinkByLanguage from 'utils/getLinkByLanguage';

import PoolCard, { PoolWithStakedValue } from './components/PoolCard/PoolCard';
import PoolTabButtons from './components/PoolTabButtons';
import { ControlContainer, StyledImage, ToggleWrapper, ViewControls } from './styles';

// import TvlDisplay from './components/TvlDisplay';

const ES_DOCS_LINK = 'https://docs.ballena.io/tecnico/productos/balle-staking-pool';
const ENG_DOCS_LINK = 'https://docs.ballena.io/v/en/technical/products/balle-staking-pool';

const Pools: React.FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const TranslateString = useI18n();
  const { selectedLanguage } = useContext(LanguageContext);
  const poolsFromState = usePools();
  const [query] = useState('');
  const { account } = useWeb3React();
  const [sortOption] = useState('hot');
  const blockchainPrices = useBlockchainPrices();
  const [visibleDYORAlert, setVisibleDYORAlert] = useState(true);

  const dispatch = useDispatch();
  const { fastRefresh } = useRefresh();
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolUserDataAsync(account));
    }
  }, [account, dispatch, fastRefresh]);

  const linkByLanguage = getLinkByLanguage(selectedLanguage.code, ES_DOCS_LINK, ENG_DOCS_LINK);
  const [docsLink, setDocsLink] = useState(linkByLanguage);
  useEffect(() => {
    setDocsLink(linkByLanguage);
  }, [linkByLanguage, selectedLanguage]);

  const [stackedOnly, setStackedOnly] = useState(false);

  const activePools = poolsFromState.filter((pool) => pool.poolState === PoolStateEnum.ACTIVE);
  const inactivePools = poolsFromState.filter((pool) => pool.poolState === PoolStateEnum.FINISHED);

  const stackedOnlyPools = activePools.filter((pool) => {
    return pool.userData && !isDustBalance(pool.userData.stakedBalance);
  });

  const sortPools = (pools: PoolWithStakedValue[]): PoolWithStakedValue[] => {
    switch (sortOption) {
      case 'apy':
        return orderBy(pools, 'apy', 'desc');
      case 'multiplier':
        return orderBy(
          pools,
          (pool: PoolWithStakedValue) => (pool.multiplier ? Number(pool.multiplier.slice(0, -1)) : 0),
          'desc',
        );
      case 'liquidity':
        return orderBy(pools, (pool: PoolWithStakedValue) => Number(pool.liquidity), 'desc');
      default:
        return pools;
    }
  };

  const poolsList = useCallback(
    (poolsToDisplay: Pool[]): PoolWithStakedValue[] => {
      let poolsToDisplayWithAPY: PoolWithStakedValue[] = poolsToDisplay.map((pool) => {
        const totalLiquidity = getPoolTvl(pool, blockchainPrices);

        const apr = getPoolApr(
          blockchainPrices[pool.stakingToken.symbol.toLowerCase()],
          blockchainPrices[pool.rewardToken.symbol.toLowerCase()],
          new BigNumber(pool.stakedTokenAmount).div(BIG_TEN_E18).toNumber(),
          new BigNumber(pool.rewardPerBlock).toNumber(),
          pool.rewardActive,
        );

        const totalAmountTokensPool = new BigNumber(pool.stakedTokenAmount).div(BIG_TEN_E18).toNumber();
        const tokenUsdPrice = totalLiquidity.toNumber() / totalAmountTokensPool;

        return { ...pool, apr, liquidity: totalLiquidity, tokenUsdPrice };
      });

      if (query) {
        const lowercaseQuery = query.toLowerCase();
        poolsToDisplayWithAPY = poolsToDisplayWithAPY.filter((pool: PoolWithStakedValue) => {
          if (pool.stakingToken.symbol.toLowerCase().includes(lowercaseQuery)) {
            return true;
          }

          return false;
        });
      }
      return poolsToDisplayWithAPY;
    },
    [query, blockchainPrices],
  );

  // const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setQuery(event.target.value);
  // };

  const isActive = !pathname.includes('history');
  let poolsStaked = [];
  if (isActive) {
    poolsStaked = stackedOnly ? poolsList(stackedOnlyPools) : poolsList(activePools);
  } else {
    poolsStaked = poolsList(inactivePools);
  }

  poolsStaked = sortPools(poolsStaked);

  const renderContent = (): JSX.Element => {
    return (
      <div>
        <FlexLayout>
          <Route exact path={`${path}`}>
            {poolsStaked.map((pool) => (
              <PoolCard key={pool.pid} pool={pool} account={account} removed={false} />
            ))}
          </Route>
          <Route exact path={`${path}/history`}>
            {poolsStaked.map((pool) => (
              <PoolCard key={pool.pid} pool={pool} account={account} removed />
            ))}
          </Route>
        </FlexLayout>
      </div>
    );
  };

  // const handleSortOptionChange = (option: OptionProps): void => {
  //   setSortOption(option.value);
  // };

  const handleClickDYORAlert = () => {
    setVisibleDYORAlert(false);
  };

  return (
    <>
      <Page>
        <PageHeader
          title={TranslateString(10238, 'Pools')}
          subtitle={TranslateString(
            1110,
            'By participating in the staking of the BALLE token, you will receive a portion of the total benefits of the platform',
          )}
          link={docsLink}
          linkText={TranslateString(810, 'LEARN MORE')}
        >
          {/* <TvlDisplay /> */}
        </PageHeader>

        {visibleDYORAlert && (
          <Alert title="" variant="warning" onClick={handleClickDYORAlert}>
            {TranslateString(
              1128,
              `The countdown is the duration of the rewards distribution for last week's platform benefits. It is renewed every week so as long as you have deposited your BALLE you are getting rewards.`,
            )}
          </Alert>
        )}

        <ControlContainer>
          <ViewControls>
            <PoolTabButtons />
            <ToggleWrapper>
              <Toggle checked={stackedOnly} onChange={() => setStackedOnly(!stackedOnly)} scale="sm" />
              <Text> {TranslateString(886, 'Deposited only')}</Text>
            </ToggleWrapper>
          </ViewControls>
          {/* <FilterContainer>
            <LabelWrapper>
              <Text>{TranslateString(926, 'Order By')}</Text>
              <Select
                options={[
                  {
                    label: TranslateString(930, 'Hot'),
                    value: 'hot',
                  },
                  {
                    label: TranslateString(574, 'APR'),
                    value: 'apr',
                  },
                  {
                    label: TranslateString(900, 'Multiplier'),
                    value: 'multiplier',
                  },
                  {
                    label: TranslateString(902, 'Liquidity'),
                    value: 'liquidity',
                  },
                ]}
                onChange={handleSortOptionChange}
              />
            </LabelWrapper>
            <LabelWrapper style={{ marginLeft: 16 }}>
              <Text>{TranslateString(928, 'Search')}</Text>
              <SearchInput onChange={handleChangeQuery} value={query} />
            </LabelWrapper>
          </FilterContainer> */}
        </ControlContainer>
        {renderContent()}
        <StyledImage src="/images/ballena/logo.png" alt="ballena.io illustration" width={120} height={103} />
      </Page>
    </>
  );
};

export default Pools;
