import { Pool, PoolStateEnum } from 'state/types';

export const getPoolBlockInfo = (pool: Pool, currentBlock: number) => {
  const { rewardStartBlock, rewardEndBlock, poolState } = pool;
  const isRetired = poolState === PoolStateEnum.FINISHED;
  const shouldShowBlockCountdown = Boolean(!isRetired && rewardStartBlock && rewardEndBlock);
  const blocksUntilStart = Math.max(rewardStartBlock - currentBlock, 0);
  const blocksRemaining = Math.max(rewardEndBlock - currentBlock, 0);
  const hasPoolStarted = blocksUntilStart === 0 && blocksRemaining > 0;
  const blocksToDisplay = hasPoolStarted ? blocksRemaining : blocksUntilStart;
  return { shouldShowBlockCountdown, blocksUntilStart, blocksRemaining, hasPoolStarted, blocksToDisplay };
};

export default null;
