import { Heading, Image, Text } from '@ballena-io/ballena-uikit';
import Page from 'components/layout/Page';
import useI18n from 'hooks/useI18n';
import React from 'react';
import styled from 'styled-components';

const Hero = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  margin-bottom: 32px;
  padding-top: 22px;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.lg} {
    background-position: left center, right center;
    height: 165px;
    padding-top: 12px;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 10px;
`;

const ComingSoon: React.FC = () => {
  const TranslateString = useI18n();

  return (
    <Page>
      <CenterContainer>
        <Image src="/images/ballena/logo.png" width={121} height={100} alt="ballena.io logo" />
      </CenterContainer>
      <Hero>
        <StyledHeading as="h1" size="xxl" mb="24px" color="primary">
          {TranslateString(1016, 'Coming Soon')}
        </StyledHeading>
        <Text fontSize="24px">{TranslateString(1018, 'Get ready for something awesome')}...</Text>
      </Hero>
    </Page>
  );
};

export default ComingSoon;
