import useWeb3 from 'hooks/useWeb3';
import { useMemo } from 'react';
import {
  getBalleMasterContract,
  getBalleMigrationContract,
  getBep20Contract,
  getStakingPoolContract,
  getCakeContract,
  getClaimRefundContract,
  getMasterchefContract,
} from 'utils/contractHelpers';

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getBep20Contract(address, web3), [address, web3]);
};

export const useBalleMigration = () => {
  const web3 = useWeb3();
  return useMemo(() => getBalleMigrationContract(web3), [web3]);
};

export const useBalleMaster = (migration: boolean) => {
  const web3 = useWeb3();
  return useMemo(() => getBalleMasterContract(migration, web3), [migration, web3]);
};

export const useStakingPool = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getStakingPoolContract(address, web3), [address, web3]);
};

// ----------------------------------------------

// TODO: Check if we need those or delete them.

export const useCake = () => {
  const web3 = useWeb3();
  return useMemo(() => getCakeContract(web3), [web3]);
};

export const useMasterchef = () => {
  const web3 = useWeb3();
  return useMemo(() => getMasterchefContract(web3), [web3]);
};

export const useClaimRefundContract = () => {
  const web3 = useWeb3();
  return useMemo(() => getClaimRefundContract(web3), [web3]);
};
