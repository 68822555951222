import { useEffect } from 'react';
import { usePriceBalleBusd } from 'state/hooks';

const useGetDocumentTitlePrice = () => {
  const ballePriceUsd = usePriceBalleBusd();
  useEffect(() => {
    document.title = `ballena.io - $${Number(ballePriceUsd).toLocaleString(undefined, {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    })}`;
  });
};
export default useGetDocumentTitlePrice;
