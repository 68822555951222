import { FarmPoolData } from './types';
import { BlockchainPrices, Vault } from '../../state/types';
import { getPancakeFarmData, getPancakeFarmApr } from './pancake';
import { getBallenaFarmData, getBallenaFarmApr } from './ballena';

/**
 * Get farm data
 * @param vault Vault to get data from (the underlying farm)
 * @returns
 */
export const getVaultFarmData = async (vault: Vault): Promise<FarmPoolData> => {
  if (vault.platform === 'Pancake') {
    return getPancakeFarmData(vault);
  }
  if (vault.platform === 'ballena.io') {
    return getBallenaFarmData();
  }
  return null;
};

/**
 * Get farm APR value in %
 * @param vault Vault to calculate APR
 * @param blockchainPrices Blockchain prices of tokens
 * @returns
 */
export const getVaultFarmApr = (
  vault: Vault,
  blockchainPrices: BlockchainPrices,
): { rewardsApr: number; lpTradeApr: number } => {
  if (vault.platform === 'Pancake') {
    return getPancakeFarmApr(vault, blockchainPrices.cake, blockchainPrices[vault.quoteToken.symbol.toLowerCase()]);
  }
  if (vault.platform === 'ballena.io') {
    return getBallenaFarmApr(vault, blockchainPrices.balle, blockchainPrices[vault.quoteToken.symbol.toLowerCase()]);
  }
  return null;
};

export default null;
