import { AddIcon, Button, Flex, IconButton, MinusIcon, Text, useModal } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import useI18n from 'hooks/useI18n';
import React, { useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { PoolStateEnum } from 'state/types';
import styled from 'styled-components';
import { getBalanceNumber, isDustBalance } from 'utils/formatBalance';

import StakeModal from '../StakeModal';
import WithdrawAllButton from './WithdrawAllButton';

interface PoolCardActionsProps {
  pid: number;
  walletTokenBalance: BigNumber;
  tokenSymbol: string;
  stakedBalance?: BigNumber;
  getTokenUrl?: string;
  tokenUsdPrice?: number;
  poolState: string;
}

const IconButtonWrapper = styled.div`
  display: flex;
  svg {
    width: 20px;
  }
`;

export const UsdPrice = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  text-align: left;
`;

const DECIMALS_NUMBER = 6;

const StakeAction: React.FC<PoolCardActionsProps> = ({
  pid,
  stakedBalance,
  tokenSymbol,
  getTokenUrl,
  walletTokenBalance,
  tokenUsdPrice,
  poolState,
}) => {
  const TranslateString = useI18n();

  // Total tokens staked
  const rawTotalStakedTokens = getBalanceNumber(stakedBalance);
  const displayTotalTokenBalance = rawTotalStakedTokens.toLocaleString(undefined, {
    minimumFractionDigits: DECIMALS_NUMBER,
  });

  const hasStakedTokens = !isDustBalance(stakedBalance);
  const isFinished = poolState === PoolStateEnum.FINISHED;

  const depositedTokensUSD = rawTotalStakedTokens * tokenUsdPrice;
  const { countUp, update } = useCountUp({
    start: 0,
    end: depositedTokensUSD,
    duration: 1,
    separator: ',',
    decimals: 2,
  });
  const updateValue = useRef(update);
  useEffect(() => {
    updateValue.current(depositedTokensUSD);
  }, [depositedTokensUSD, updateValue]);

  const [onPresentDeposit] = useModal(
    <StakeModal
      pid={pid}
      getTokenUrl={getTokenUrl}
      stakingMax={walletTokenBalance}
      stakingTokenPrice={tokenUsdPrice}
      tokenSymbol={tokenSymbol}
    />,
  );

  const [onPresentWithdraw] = useModal(
    <StakeModal
      pid={pid}
      stakingMax={stakedBalance}
      stakingTokenPrice={tokenUsdPrice}
      tokenSymbol={tokenSymbol}
      isRemovingStake
    />,
  );

  const renderStakingButtons = () => {
    return hasStakedTokens ? (
      <IconButtonWrapper>
        <IconButton variant="secondary" onClick={onPresentWithdraw} mr="6px">
          <MinusIcon color="primary" width="14px" />
        </IconButton>
        <IconButton variant="secondary" onClick={onPresentDeposit}>
          <AddIcon color="primary" width="14px" />
        </IconButton>
      </IconButtonWrapper>
    ) : (
      <Button onClick={onPresentDeposit} variant="secondary">
        {TranslateString(538, 'Deposit')}
      </Button>
    );
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex flexDirection="column">
        <Flex justifyContent="space-between" alignItems="center">
          <Text color={hasStakedTokens ? 'text' : 'textDisabled'} fontSize="24px" textAlign="left">
            {displayTotalTokenBalance}
          </Text>
        </Flex>
        <UsdPrice>~{countUp}USD</UsdPrice>
      </Flex>

      {isFinished ? (
        <WithdrawAllButton pid={pid} tokenBalance={stakedBalance} tokenSymbol={tokenSymbol} />
      ) : (
        renderStakingButtons()
      )}
    </Flex>
  );
};

export default StakeAction;
