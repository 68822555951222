import BigNumber from 'bignumber.js/bignumber';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export const BSC_BLOCK_TIME = 3;
export const BASE_BSC_SCAN_URL = 'https://bscscan.com';
export const BASE_URL = 'https://pancakeswap.finance';
export const BASE_EXCHANGE_URL = 'https://pancakeswap.finance';
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`;
export const BASE_SWAP_URL = `${BASE_EXCHANGE_URL}/swap?outputCurrency=`;
