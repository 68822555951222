import { AutoRenewIcon, BalanceInput, Button, Flex, Image, Link, Modal, Slider, Text } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import useI18n from 'hooks/useI18n';
import { useStake, useStakeAll } from 'hooks/useStake';
import useToast from 'hooks/useToast';
import { useUnstake, useUnstakeAll } from 'hooks/useUnstake';
import React, { useState } from 'react';
import { usePoolFromPid } from 'state/hooks';
import styled from 'styled-components';
import { getAddress } from 'utils/addressHelpers';
import { sendTransferTokensEvent } from 'utils/events';
import { formatNumber, getDecimalAmount, getFullDisplayBalance } from 'utils/formatBalance';

import PercentageButton from './PercentageButton';

interface StakeModalProps {
  pid: number;
  getTokenUrl?: string;
  stakingMax: BigNumber;
  stakingTokenPrice: number;
  tokenSymbol: string;
  isRemovingStake?: boolean;
  onDismiss?: () => void;
}

const StyledLink = styled(Link)`
  width: 100%;
`;

const STAKING_TOKEN_DECIMALS = 18;
const DISPLAY_DECIMALS = 6;

const StakeModal: React.FC<StakeModalProps> = ({
  pid,
  getTokenUrl,
  stakingMax,
  stakingTokenPrice,
  tokenSymbol,
  isRemovingStake = false,
  onDismiss,
}) => {
  const TranslateString = useI18n();
  const { poolAddresses } = usePoolFromPid(pid);
  const poolAddress = getAddress(poolAddresses);
  const { onStake } = useStake(poolAddress);
  const { onStakeAll } = useStakeAll(poolAddress);
  const { onUnstake } = useUnstake(poolAddress);
  const { onUnstakeAll } = useUnstakeAll(poolAddress);
  const { toastSuccess, toastError } = useToast();

  const [pendingTx, setPendingTx] = useState(false);
  const [stakeAmount, setStakeAmount] = useState('');
  const [percent, setPercent] = useState(0);

  const tokenName = tokenSymbol.toUpperCase();
  const usdValueStaked = stakeAmount && formatNumber(new BigNumber(stakeAmount).times(stakingTokenPrice).toNumber());

  const handleStakeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value || '0';
    const convertedInput = getDecimalAmount(new BigNumber(inputValue), STAKING_TOKEN_DECIMALS);
    const percentage = Math.floor(convertedInput.dividedBy(stakingMax).multipliedBy(100).toNumber());

    setStakeAmount(inputValue);
    setPercent(Math.min(percentage, 100));
  };

  const handleChangePercent = (sliderPercent: number) => {
    const percentageOfStakingMax = stakingMax.dividedBy(100).multipliedBy(sliderPercent);
    const amountToStake = getFullDisplayBalance(percentageOfStakingMax, STAKING_TOKEN_DECIMALS, DISPLAY_DECIMALS);

    setStakeAmount(amountToStake);
    setPercent(sliderPercent);
  };

  const showToastError = () =>
    toastError(TranslateString(966, 'Canceled'), TranslateString(968, 'Please try again and confirm the transaction.'));

  const handleConfirmClick = async () => {
    setPendingTx(true);

    if (isRemovingStake) {
      // Withdraw
      try {
        const withdrawCall = percent === 100 ? onUnstakeAll() : onUnstake(stakeAmount);
        const Tx = await withdrawCall;

        if (!Tx) {
          setPendingTx(false);
          onDismiss();
          showToastError();
        }
        if (Tx && Tx.status === true) {
          sendTransferTokensEvent('withdraw', `${tokenName}-pool`, stakeAmount);

          toastSuccess(
            TranslateString(962, 'Withdrawn'),
            `${tokenSymbol} ${TranslateString(964, 'and BALLE rewards have been sent to your wallet!')}`,
          );
          setPendingTx(false);
          onDismiss();
        }
      } catch (e) {
        showToastError();
        setPendingTx(false);
      }
    } else {
      try {
        // Deposit
        const depositCall = percent === 100 ? onStakeAll() : onStake(stakeAmount);
        const Tx = await depositCall;

        if (!Tx) {
          setPendingTx(false);
          onDismiss();
          showToastError();
        }

        if (Tx && Tx.status === true) {
          sendTransferTokensEvent('deposit', `${tokenName}-pool`, stakeAmount);

          setPendingTx(false);
          onDismiss();
          toastSuccess(
            TranslateString(948, 'Deposit'),
            `${tokenSymbol} ${TranslateString(970, 'have been deposited in the vault!')}`,
          );
        }
      } catch (e) {
        showToastError();
        setPendingTx(false);
      }
    }
  };

  const poolImage = tokenSymbol.split(' ')[0].toLocaleLowerCase();

  return (
    <Modal
      title={
        isRemovingStake
          ? `${TranslateString(954, 'Withdraw')} ${tokenName} Tokens`
          : `${TranslateString(316, 'Deposit')} ${tokenName} Tokens`
      }
      onDismiss={onDismiss}
    >
      <Flex alignItems="center" justifyContent="space-between" mb="8px">
        <Text>{isRemovingStake ? TranslateString(954, 'Withdraw') : TranslateString(538, 'Deposit')}:</Text>
        <Flex alignItems="center" minWidth="150px" justifyContent="flex-end">
          <Image src={`/images/pools/${poolImage}.svg`} width={32} height={32} alt={tokenSymbol} />
          <Text ml="8px" bold>
            {tokenSymbol}
          </Text>
        </Flex>
      </Flex>
      <BalanceInput
        value={stakeAmount}
        onChange={handleStakeInputChange}
        currencyValue={`~${usdValueStaked || 0} USD`}
      />
      <Text mt="8px" ml="auto" color="textSubtle" fontSize="12px" mb="8px">
        Balance: {getFullDisplayBalance(stakingMax, STAKING_TOKEN_DECIMALS, DISPLAY_DECIMALS)}
      </Text>
      <Slider
        min={0}
        max={100}
        value={percent}
        onValueChanged={handleChangePercent}
        name="stake"
        valueLabel={`${percent}%`}
        step={1}
      />
      <Flex alignItems="center" justifyContent="space-between" mt="8px">
        <PercentageButton onClick={() => handleChangePercent(25)}>25%</PercentageButton>
        <PercentageButton onClick={() => handleChangePercent(50)}>50%</PercentageButton>
        <PercentageButton onClick={() => handleChangePercent(75)}>75%</PercentageButton>
        <PercentageButton onClick={() => handleChangePercent(100)}>MAX</PercentageButton>
      </Flex>
      <Button
        isLoading={pendingTx}
        endIcon={pendingTx ? <AutoRenewIcon spin color="primary" /> : null}
        onClick={handleConfirmClick}
        disabled={!stakeAmount || parseFloat(stakeAmount) === 0}
        mt="24px"
      >
        {pendingTx ? TranslateString(960, 'Confirming') : TranslateString(666, 'Confirm')}
      </Button>

      {!isRemovingStake && (
        <StyledLink external href={getTokenUrl}>
          <Button width="100%" mt="8px" variant="secondary">
            {`${TranslateString(878, 'Get')} ${tokenName}`}
          </Button>
        </StyledLink>
      )}
    </Modal>
  );
};

export default StakeModal;
