import { Heading, LinkExternal } from '@ballena-io/ballena-uikit';
import React from 'react';
import styled from 'styled-components';

import BalleDistributionDisplay from '../VaultsBalleDistribution';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px 32px 0;
  background: ${({ theme }) => theme.colors.background};

  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const DocsLink = styled(LinkExternal)`
  alignself: 'center';
  padding-top: 16px;
`;

interface PageHeaderProps {
  children?: React.ReactNode;
  title: string;
  subtitle: string;
  link?: string;
  linkText?: string;
  isVault?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ children, title, subtitle, link, linkText, isVault }) => {
  return (
    <Header>
      <div>
        <Heading as="h1" size="xxl" color="primary" mb="24px">
          {title}
        </Heading>
        <Heading size="lg" color="text">
          {subtitle}
        </Heading>
        {link && linkText && <DocsLink href={link}>{linkText}</DocsLink>}
        {isVault && <BalleDistributionDisplay />}
      </div>
      {children && children}
    </Header>
  );
};

export default PageHeader;
