import { ResetCSS } from '@ballena-io/ballena-uikit';
import BigNumber from 'bignumber.js';
import useEagerConnect from 'hooks/useEagerConnect';
import React, { lazy, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useFetchPublicData } from 'state/hooks';

import Menu from './components/Menu';
import PageLoader from './components/PageLoader';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import ToastListener from './components/ToastListener';
import useGetDocumentTitlePrice from './hooks/useGetDocumentTitlePrice';
import history from './routerHistory';
import GlobalStyle from './style/Global';
import ComingSoon from './views/ComingSoon';
import Pools from './views/Pools';

// Route-based code splitting
const Home = lazy(() => import('./views/Home'));
const Vaults = lazy(() => import('./views/Vaults'));
const NotFound = lazy(() => import('./views/NotFound'));

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  // GTM
  (window as any).dataLayer.push({
    event: 'pageview',
  });

  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null;
  }, []);

  useEagerConnect();
  useFetchPublicData();
  useGetDocumentTitlePrice();
  // useFetchPriceList();

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/vaults">
              <Vaults />
            </Route>
            <Route path="/coming-soon">
              <ComingSoon />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
    </Router>
  );
};

export default React.memo(App);
