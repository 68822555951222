import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

const STANDARD_GAS = 525000;
const LOW_GAS = 200000;

export const approveAddress = async (tokenContract, spenderAddress, account) => {
  return tokenContract.methods
    .approve(spenderAddress, ethers.constants.MaxUint256)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const stake = async (poolContract, amount, account) => {
  return poolContract.methods
    .stake(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString())
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const stakeAll = async (poolContract, account) => {
  return poolContract.methods
    .stakeAll()
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const unstake = async (poolContract, amount, account) => {
  return poolContract.methods
    .withdraw(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString())
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const unstakeAll = async (poolContract, account) => {
  return poolContract.methods
    .withdrawAll()
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const stakingHarvest = async (poolContract, account) => {
  return poolContract.methods
    .withdraw('0')
    .send({ from: account, gas: LOW_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const approve = async (lpContract, balleMasterContract, account) => {
  return lpContract.methods
    .approve(balleMasterContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const deposit = async (balleMasterContract, vid, amount, account) => {
  return balleMasterContract.methods
    .deposit(vid, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString())
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const withdraw = async (balleMasterContract, vid, amount, account) => {
  return balleMasterContract.methods
    .withdraw(vid, new BigNumber(amount).times(new BigNumber(10).pow(18)).toString())
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const harvest = async (balleMasterContract, vid, account) => {
  return balleMasterContract.methods
    .deposit(vid, '0')
    .send({ from: account, gas: LOW_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const withdrawAll = async (balleMasterContract, vid, account) => {
  return balleMasterContract.methods
    .withdrawAll(vid)
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const depositAll = async (balleMasterContract, vid, account) => {
  return balleMasterContract.methods
    .depositAll(vid)
    .send({ from: account, gas: STANDARD_GAS })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

// -----------------------------------

export const sousStake = async (sousChefContract, amount, decimals = 18, account) => {
  return sousChefContract.methods
    .deposit(new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toString())
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const sousStakeBnb = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 200000, value: new BigNumber(amount).times(new BigNumber(10).pow(18)).toString() })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const sousUnstake = async (sousChefContract, amount, decimals = 18, account) => {
  // shit code: hard fix for old CTK and BLK
  if (sousChefContract.options.address === '0x3B9B74f48E89Ebd8b45a53444327013a2308A9BC') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash;
      });
  }
  if (sousChefContract.options.address === '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash;
      });
  }
  if (sousChefContract.options.address === '0x453a75908fb5a36d482d5f8fe88eca836f32ead5') {
    return sousChefContract.methods
      .emergencyWithdraw()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        return tx.transactionHash;
      });
  }

  return sousChefContract.methods
    .withdraw(new BigNumber(amount).times(new BigNumber(10).pow(decimals)).toString())
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const sousEmegencyUnstake = async (sousChefContract, amount, account) => {
  return sousChefContract.methods
    .emergencyWithdraw()
    .send({ from: account })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const soushHarvest = async (sousChefContract, account) => {
  return sousChefContract.methods
    .deposit('0')
    .send({ from: account, gas: 200000 })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};

export const soushHarvestBnb = async (sousChefContract, account) => {
  return sousChefContract.methods
    .deposit()
    .send({ from: account, gas: 200000, value: new BigNumber(0) })
    .on('transactionHash', (tx) => {
      return tx.transactionHash;
    });
};
