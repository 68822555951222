import { BinanceIcon, CommunityIcon, ErrorIcon, RemoveIcon, Tag, VerifiedIcon } from '@ballena-io/ballena-uikit';
import React from 'react';

const CoreTag = (props) => (
  <Tag variant="secondary" outline startIcon={<VerifiedIcon />} {...props}>
    Core
  </Tag>
);

const CommunityTag = (props) => (
  <Tag variant="textSubtle" outline startIcon={<CommunityIcon />} {...props}>
    Community
  </Tag>
);

const BinanceTag = (props) => (
  <Tag variant="binance" outline startIcon={<BinanceIcon />} {...props}>
    Binance
  </Tag>
);

const DualTag = (props) => (
  <Tag variant="textSubtle" outline {...props}>
    Dual
  </Tag>
);

const PlatformTag = ({ text }) => (
  <Tag variant="textSubtle" outline>
    {text}
  </Tag>
);

const RetiredTag = (props) => (
  <Tag variant="failure" outline startIcon={<RemoveIcon />} {...props}>
    RETIRED
  </Tag>
);

const PausedTag = (props) => (
  <Tag variant="binance" outline startIcon={<ErrorIcon />} {...props}>
    PAUSED
  </Tag>
);

export { CoreTag, CommunityTag, BinanceTag, DualTag, PlatformTag, RetiredTag, PausedTag };
